<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const page_loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_campaigns = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_campaign_report = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const campaigns = ref([])
const campaign = ref({"items": []})
const selected_campaign = ref(null)
const new_campaign = ref({})

const product_infos = ref({})

const selected_xls_file = ref(null)
const selected_xls_file_name = ref(null)
const fileUpload = ref(null)
const selected_products = ref()

const expanded_campaign_products = ref([])
const new_campaign_dialog = ref(false)
const edit_campaign_dialog = ref(false)
const product_infos_slider = ref(false)
const price_calculator_slider = ref(false)
const view_campaign_report = ref(false)

const locations = ref([])
const location = ref(null)
const statuses = [
    {name: 'Active', code: 1},
    {name: 'Inactive', code: 2},
    {name: 'Pending', code: 3}
]
const status = ref(null)

const price_calculator_items = ref([])

onMounted( async () => {
    page_loader.value = true
    get_campaigns()
    get_locations()
    if (localStorage.getItem('selected_campaign')) {
        selected_campaign.value = localStorage.getItem('selected_campaign')
        get_campaign()
    }else{
        page_loader.value = false
    }
})

const get_campaigns = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-campaigns')
    .then(response => {
        console.log(response.data)
        campaigns.value = response.data
        loader.value = false
    })
}

const get_campaign = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-campaign', {id: selected_campaign.value})
    .then(response => {
        console.log(response.data)
        campaign.value = response.data
        loader.value = false
        page_loader.value = false
    })
}

const select_campaign = () => {
    localStorage.setItem('selected_campaign', selected_campaign.value)
    get_campaign()
}

const get_locations = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-locations')
    .then(response => {
        console.log(response.data)
        locations.value = response.data
        loader.value = false
    })
}

const onSelectFile = (event) => {
    let reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
        selected_xls_file.value = reader.result
    }
    selected_xls_file_name.value = event.target.files[0]
}

const create_new_campaign = async () => {
    //loader.value = true
    let payload = {
        "name": new_campaign.value.name,
        "runtime": new_campaign.value.runtime,
        "file": selected_xls_file.value,
        "filename": selected_xls_file_name.value.name
    }
    console.log("new campaign", payload)
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/create-new-campaign", payload)
        .then(response => {
                new_campaign_dialog.value = false
                toast.add({severity:'success', summary: 'Einkauf erstellt', detail: 'Der Einkauf wurde erfolgreich erstellt.', life: 3000})
                loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const show_product_infos = (e) => {
    product_infos.value = e.data
    product_infos_slider.value = true
    product_infos.value["Similar products"] = []
    product_infos.value["margin"] = product_infos.value["price_unit"] - product_infos.value["cost_unit"]
    product_infos.value["units"] = product_infos.value["purchase_quantity"] - product_infos.value["sale_quantity"]
    product_infos.value["revenue"] = product_infos.value["price_unit"] * product_infos.value["units"]
    product_infos.value["profit"] = (product_infos.value["price_unit"] * product_infos.value["units"]) - (product_infos.value["margin"] * product_infos.value["units"])
    product_infos.value["left_over_after_campaign_end"] = (product_infos.value["purchase_quantity"] - product_infos.value["sale_quantity"]) - (product_infos.value["campaign_days_left"] * product_infos.value["average_sales_per_day"])
    console.log(e.data)
}

watch ([product_infos], (currentValue, oldValue) => {
    console.log("watch triggered")
    product_infos.value["margin"] = product_infos.value["price_unit"] - product_infos.value["cost_unit"]
    product_infos.value["units"] = product_infos.value["purchase_quantity"] - product_infos.value["sale_quantity"]
    product_infos.value["revenue"] = product_infos.value["price_unit"] * product_infos.value["units"]
    product_infos.value["profit"] = (product_infos.value["price_unit"] * product_infos.value["units"]) - (product_infos.value["margin"] * product_infos.value["units"])
    product_infos.value["left_over_after_campaign_end"] = (product_infos.value["purchase_quantity"] - product_infos.value["sale_quantity"]) - (product_infos.value["campaign_days_left"] * product_infos.value["average_sales_per_day"])
}, {deep: true })

const search_similar_products = (item_name) => {
    console.log(item_name)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/search-similar-products', {item_name: item_name})
    .then(response => {
        product_infos.value["Similar products"] = response.data
    })
}

const campaign_details = (event) => {
    console.log(event.data)
    new_campaign.value = event.data
    edit_campaign_dialog.value = true
}

const edit_new_campaign = async () => {
    console.log(new_campaign.value)
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/save-edit-campaign", new_campaign.value)
        .then(response => {
            get_campaigns()
            edit_campaign_dialog.value = false
            toast.add({severity:'success', summary: 'Campaign was successfully edited', life: 3000})
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const campaign_report_data = ref({})
const campaign_report = (event) => {
    console.log(event.data)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/campaign-report", {id: event.data.id})
        .then(response => {
            campaign_report_data.value = response.data
            console.log("campaign_report", campaign_report_data.value)
            view_campaign_report.value = true
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const calculate_repricing = () => {
    console.log(selected_products.value)
    price_calculator_items.value = []
    selected_products.value.forEach(element => {
        element["new_price"] = element["price_unit"]
        price_calculator_items.value.push(element)
    })
    console.log("calc_items", price_calculator_items)
    price_calculator_slider.value = true
}

const calculate_totals = (items) => {
    let total_new_price = 0
    let total_price = 0
    let total_cost_price = 0
    items.forEach(element => {
        total_new_price += (element["new_price"] * element["total_purchase_quantity"])
        total_price += (element["price_unit"] * element["total_purchase_quantity"])
        total_cost_price += (element["cost_unit"] * element["total_purchase_quantity"])
    })
    console.log("calculating totals...", total_new_price, total_price, total_cost_price, total_new_price - total_cost_price)
    return total_new_price - total_cost_price
}

const calculate_totals_percent = (items) => {
    let total_new_price = 0
    let total_price = 0
    let total_cost_price = 0
    items.forEach(element => {
        total_new_price += (element["new_price"] * element["total_purchase_quantity"])
        total_price += (element["price_unit"] * element["total_purchase_quantity"])
        total_cost_price += (element["cost_unit"] * element["total_purchase_quantity"])
    })
    console.log("calculating totals in percent...", total_new_price, total_price, total_cost_price, Math.round(total_new_price / total_price * 100))
    return Math.round(total_new_price / total_price * 100)
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_number = (value) => {
    return Number(value).toLocaleString('de-CH');
}

function Percentage(part, whole) {
    if (whole === 0) {
        return 0 // Avoid division by zero
    }
    // check if numbers are negatives and convert to positives
    if (part < 0) {
        part = part * -1
    }
    if (whole < 0) {
        whole = whole * -1
    }
    const percentage = (part / whole) * 100
    return Math.round(percentage)
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader || page_loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_campaigns['global'].value" placeholder="Suche" style="width: 400px" />
                    </span>
                    <Button label="New campaign" @click="new_campaign = {}, new_campaign_dialog = true" icon="pi pi-plus" class="mr-2" />
                    <Dropdown v-model="selected_campaign" optionLabel="name" optionValue="id" :options="campaigns" placeholder="Select a campaign" @change="select_campaign" style="width: 400px" />
                </template>
                <template #end>
                    <Button v-if="selected_products" label="Calculate Prices" icon="pi pi-calculator" class="bg-purple-600 border-purple-700 text-white mr-2" :badge="String(selected_products.length)" @click="calculate_repricing()" />
                    <Button label="Export" icon="pi pi-file-excel" class="mr-2" />
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable v-model:selection="selected_products" :value="campaign['items']" @row-click="show_product_infos" :rows="50" responsiveLayout="scroll" :rowHover="true" class="rounded">
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column field="Campaign" header="Campaign" sortable style="width: 10%" />
                <Column field="Item no." header="Item no." sortable style="width: 10%" />
                <Column field="Item name" header="Item name" style="width: 40%" />
                <Column header="Runtime" sortable style="width: 20%">
                    <template #body="slotProps">
                        <ProgressBar :value="Percentage(slotProps.data.campaign_duration - slotProps.data.campaign_days_left, slotProps.data.campaign_duration)" v-tooltip.top="{value: 'Start: ' + format_swiss_date(slotProps.data.campaign_start_date) + '<br />End: ' + format_swiss_date(slotProps.data.campaign_end_date) + '<br />Days left: ' + String(slotProps.data.campaign_days_left), escape: false}"> {{ Percentage(slotProps.data.campaign_duration - slotProps.data.campaign_days_left, slotProps.data.campaign_duration)  }}%</ProgressBar>
                    </template>
                </Column>
                <Column header="Sales" style="width: 20%">
                    <template #body="slotProps">
                        <ProgressBar :value="Percentage(slotProps.data.sale_quantity, slotProps.data.purchase_quantity)" v-tooltip.top="{value: 'Sold: ' + String(slotProps.data.sale_quantity) + '<br />Purchased: ' + String(slotProps.data.purchase_quantity) + '<br />Rest: ' + String(slotProps.data.purchase_quantity - slotProps.data.sale_quantity), escape: false}"> {{ Percentage(slotProps.data.sale_quantity, slotProps.data.purchase_quantity) }}%</ProgressBar>
                    </template>
                </Column>
                <Column header="Days left" style="width: 10%">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.selling_out" v-tooltip.top="{value: 'Estimated days left: ' + String(slotProps.data.estimated_sales_days_remaining) + '<br />Estimated stock after campaign end: ' + String(slotProps.data.estimated_purchase_remaining) + '<br />Estimated days after campaign end: ' + String(slotProps.data.estimated_days_over_campaign), escape: false}" class="p-tag p-tag-success mr-2">{{slotProps.data.estimated_sales_days_remaining}}</span>
                        <span v-if="!slotProps.data.selling_out" v-tooltip.top="{value: 'Estimated days left: ' + String(slotProps.data.estimated_sales_days_remaining) + '<br />Estimated stock after campaign end: ' + String(slotProps.data.estimated_purchase_remaining) + '<br />Estimated days after campaign end: ' + String(slotProps.data.estimated_days_over_campaign), escape: false}" class="p-tag p-tag-danger mr-2">{{slotProps.data.estimated_sales_days_remaining}}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    <!----------------------------------------------->
    <!---------------- Edit Campaign ----------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="edit_campaign_dialog" :style="{width: '800px'}" header="Edit Campaign" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="new_campaign.name" class="w-full" />
                    <label for="name">Campaign name</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar v-model="new_campaign.runtime" selectionMode="range" :manualInput="false" dateFormat="dd.mm.yy" />
                    <label for="name">Campaign runtime</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button icon="pi pi-save" :disabled="new_campaign.name || new_campaign.runtime ? false : true" label="Save changes" @click="edit_new_campaign" class="w-auto" />
            </div>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!-------------- Campaign Report ---------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="view_campaign_report" :style="{width: '100%'}" header="Edit Campaign" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_campaign_report['global'].value" placeholder="Suche" style="width: 300px" />
                    </span>
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable v-model:filters="filter_campaign_report" :value="campaign_report_data.items" :rows="100" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[100,400,800]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="Campaign" header="Campaign" sortable>
                    <template #body="slotProps">
                        <span >{{slotProps.data.Campaign}}</span>
                    </template>
                </Column>
                <Column field="Item no." header="Item no." sortable>
                    <template #body="slotProps">
                        <span class="font-bold mr-3">{{slotProps.data["Item no."]}}</span>
                    </template>
                </Column>
                <Column field="Item name" header="Item name" sortable>
                    <template #body="slotProps">
                        <span class="mr-2">{{slotProps.data["Item name"]}}</span>
                        <span>({{format_currency(slotProps.data["price_unit"])}})</span>
                    </template>
                </Column>
                <Column field="Main category" header="Main category" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data["Main category"]}}</span>
                    </template>
                </Column>
                <Column field="Sub category" header="Sub category" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data["Sub category"]}}</span>
                    </template>
                </Column>
                <Column field="Display type" header="Display type" sortable>
                    <template #body="slotProps">
                        <span>{{slotProps.data["Display type"]}}</span>
                    </template>
                </Column>
                <Column field="total_profit2" header="Profit" sortable>
                    <template #body="slotProps">
                        <span>{{format_currency(slotProps.data["total_profit2"])}}</span>
                    </template>
                </Column>
                <Column field="total_purchase_quantity" header="Purchased" sortable>
                    <template #body="slotProps">
                        <span>{{format_number(slotProps.data["total_purchase_quantity"])}}</span>
                    </template>
                </Column>
                <Column field="total_sales_quantity" header="Sold" sortable>
                    <template #body="slotProps">
                        <span>{{format_number(slotProps.data["total_sales_quantity"])}}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!---------------- New Campaign ----------------->
    <!----------------------------------------------->
    <Dialog v-model:visible="new_campaign_dialog" :style="{width: '800px'}" header="New Campaign" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="new_campaign.name" class="w-full" />
                    <label for="name">Campaign name</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar v-model="new_campaign.runtime" selectionMode="range" :manualInput="false" dateFormat="dd.mm.yy" />
                    <label for="name">Campaign runtime (optional)</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div class="flex align-content-center flex-wrap">
                    <input type="file" @change="onSelectFile" style="display: none" ref="fileUpload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <Button :label="selected_xls_file_name ? selected_xls_file_name.name : 'Upload XLSX File'" @click="fileUpload.click()" class="w-auto mr-4 mb-1" icon="pi pi-upload" />
                    <div class="text-primary font-bold cursor-pointer flex align-items-center justify-content-center"><span @click="open_url('https://cdn.snappy-pages.snpy.ch/flyingtiger/templates/purchase_template_flyingtiger_CH01.xlsx')"><i class="pi pi-file-excel mr-1" />Download excel template</span></div>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="new_campaign.name || new_campaign.runtime || selected_xls_file_name ? false : true" label="Create new campaign" @click="create_new_campaign" class="w-auto" />
            </div>
        </div>
    </Dialog>
    <!----------------------------------------------->
    <!------------ Product Infos Slider ------------->
    <!----------------------------------------------->
    <Sidebar v-model:visible="product_infos_slider" position="right" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="col-12">
                <Image preview :src="'https://productimages.flyingtiger.com/ItemImages/' + product_infos['Item no.'] + '.jpg'" v-tooltip.right="{ value: '<img src=\'https://productimages.flyingtiger.com/ItemImages/' + product_infos['Item no.'] + '.jpg\' />', escape: false}" class="w-12rem w-12rem shadow-2" />
            </div>
        </div>
        <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab header="Product specs">
                <div class="col-12 formgrid grid">
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Campaign
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Campaign"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Display type
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Display type"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Commercial theme
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Commercial theme"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        General info
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["General info"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Item name
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Item name"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Item no.
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Item no."]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Item status
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Item status"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Main category
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Main category"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Product type
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Product type"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Reference item
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Reference item"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        Sub category
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["Sub category"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        VmLocation
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["VmLocation"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        VmModule
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["VmModule"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        purchase_quantity
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["purchase_quantity"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        sale_quantity
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["sale_quantity"]}}
                    </div>
                    <div class="col-6 font-bold bg-blue-50 border-bottom-1 border-blue-400">
                        sale_turnover
                    </div>
                    <div class="col-6 border-bottom-1 border-blue-400">
                        {{product_infos["sale_turnover"]}}
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
    </Sidebar>
    <Sidebar v-model:visible="price_calculator_slider" position="right" style="width: 70%">
        <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab header="Revenue calculator">
                <div class="col-12 formgrid grid">
                    <DataTable :value="price_calculator_items" :rows="100" responsiveLayout="scroll" :rowHover="true" class="rounded w-full">
                        <Column field="Item no." header="Item no." sortable>
                            <template #body="slotProps">
                                <span class="font-bold mr-3">{{slotProps.data["Item no."]}}</span>
                            </template>
                        </Column>
                        <Column field="Item name" header="Item name" sortable>
                            <template #body="slotProps">
                                <span class="mr-2">{{slotProps.data["Item name"]}}</span>
                            </template>
                        </Column>
                        <Column field="price_unit" header="Price" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency(slotProps.data.price_unit)}}</span>
                            </template>
                        </Column>
                        <Column field="cost_unit" header="Cost" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency(slotProps.data.cost_unit)}}</span>
                            </template>
                        </Column>
                        <Column field="quantity" header="Quantity" sortable>
                            <template #body="slotProps">
                                <span>{{format_number(slotProps.data.total_purchase_quantity - slotProps.data.total_sales_quantity)}}</span>
                            </template>
                        </Column>
                        <Column field="profit" header="Profit" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency((slotProps.data.price_unit - slotProps.data.cost_unit) * (slotProps.data.total_purchase_quantity - slotProps.data.total_sales_quantity))}} ({{ Math.round((100 * (1 - (slotProps.data.cost_unit / slotProps.data.price_unit))), 2)}}%)</span>
                            </template>
                        </Column>
                        <Column field="new_price" header="New price" sortable>
                            <template #body="slotProps">
                                <InputNumber v-model="slotProps.data.new_price" locale="ch-DE" :minFractionDigits="2" />
                            </template>
                        </Column>
                        <Column field="profit_after" header="Profit after" sortable>
                            <template #body="slotProps">
                                <span>{{format_currency((slotProps.data.new_price - slotProps.data.cost_unit) * (slotProps.data.total_purchase_quantity - slotProps.data.total_sales_quantity))}} ({{ Math.round(100*(1 - (slotProps.data.cost_unit / slotProps.data.new_price)), 2)}}%)</span>
                            </template>
                        </Column>
                    </DataTable>
                    <div class="field col-12 mt-3">
                        <ProgressBar :value="calculate_totals_percent(price_calculator_items)" v-tooltip.top="{value: 'Left over after campaign end', escape: false}">Profit: {{format_currency(calculate_totals(price_calculator_items))}} ({{calculate_totals_percent(price_calculator_items)}}%)</ProgressBar>
                    </div>
                    <div class="field col-12 mt-3">
                        <Button label="Launch price change workflow" icon="pi pi-sitemap" class="w-auto" />
                    </div>
                </div> 
            </AccordionTab>
        </Accordion>
    </Sidebar>
    </BlockUI>
</template>